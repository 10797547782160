/**
 * @file components/password/set_password_form.js
 * this file is repeated in first time set password, password reset and password update
 */

import React from "react";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PAGES from "src/constants/pages";
import {
  RESET_PWD_ERROR_DISPLAY,
  PASSWORD_FORMAT_ERRORS,
  SET_PASSWORD_ERROR_DISPLAY,
  SET_PASSWORD_ERROR_RESPONSES,
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
} from "src/constants/errors";
import { TOKEN_TYPE } from "src/constants/tokenType";
import { EDIT_PERSONAL_MODAL } from "src/constants/editPersonalModal";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { WORKFLOW, WORKFLOW_VALUE } from "src/constants/workflow";
import { utils_validation } from "src/utils/utils_validation";
import { appuser_validaterepeatedpassword } from "src/utils/validationrules_utils";
import { utils } from "src/utils/utils_general";

import { update_password } from "src/utils/validationrules_api_app";
import {
  reset_password_put,
  update_password_put,
  set_first_time_password_put,
  verify_password_post,
} from "src/actions/passwordAction";
import Spinner from "src/components/global/spinner";
import SetTwoFA from "src/components/profile/two-factor-authentication";
import utilsGA, { eventEnum, formNames } from "src/utils/utils_ga";
import {get_utm_params, update_user_utm_params} from "../../actions/utmParamsAction";

class SetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    let formName = this.getFormName(props.page);
    this.state = {
      submitted: false,
      password: "",
      currentPassword: "",
      passwordFormatErrors: null,
      errors: {},
      user: null,
      showPassword: false,
      passwordFocus: false,
      show2FA: false,
      loader: false,
      start_form: false,
      formName,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.passwordField = React.createRef();
    this.currentPasswordField = React.createRef();
  }

  componentDidMount() {
    if (this.props.user) {
      this.setState({ user: this.props.user });
    }
    this.props.get_utm_params();
  }

  componentDidUpdate() {
    if (this.props.user && !this.state.user) {
      this.setState({ user: this.props.user });
    }
  }

  getFormName(page) {
    let formName = "";
    switch (page) {
      case "signup":
        formName = formNames.signupPassword;
        break;
      case "reset-password":
        formName = formNames.resetPassword;
        break;
      case "profile":
        formName = formNames.profilePassword;
        break;
      default:
        formName = "undefined";
        break;
    }
    return formName;
  }

  handleSubmit(e) {
    e.preventDefault();
    utilsGA.sendFormSubmitToGA(this.state.formName);
    this.setState({ errors: {} });
    const isValid = this.validate(this.state.password);
    const loggedInSessionTypes = [TOKEN_TYPE.SESSION, TOKEN_TYPE.ENROLLMENT];
    const isLoggedIn =
      this.state.user &&
      loggedInSessionTypes.includes(this.state.user.token_type);
    const currentPassword = isLoggedIn ? this.state.currentPassword : null;
    if (isValid) {
      const registrationType =
        this.props.registrationType ||
        utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE);
      const acceptNoUser =
        registrationType === TOKEN_TYPE.REGISTRATION_SELF ||
        registrationType === TOKEN_TYPE.REGISTRATION_ADMIN;
      if (acceptNoUser && !this.state.user) {
        const flow =
          this.props.registrationFlow ||
          utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
        if (flow) {
          flow.map((c) => {
            if (c.component === WORKFLOW.SET_PASSWORD) {
              c.value = WORKFLOW_VALUE.COMPLETE;
            }
            return c;
          });
          this.props.selfRegistrationPasswordSubmit(flow, {
            password: this.state.password,
          });
        }
      } else {
        this.submitPassword(this.state.password, currentPassword);
      }
    } else {
      utilsGA.sendFormFieldsErrorsEventToGA(this.state.formName, {
        isValid: false,
      });
    }
  }

  submitPassword(new_password, currentPassword) {
    if (this.props.is2FAEnabled) {
      this.verifyPassword(currentPassword, new_password);
    } else {
      this.setPassword(new_password);
    }
  }

  validate(new_password) {
    if (this.state.user && this.state.user.token_type === TOKEN_TYPE.SESSION) {
      return this.validateUpdatedPassword(new_password);
    }
    return this.validateNewPassword(new_password);
  }

  validateUpdatedPassword(new_password) {
    const errorMessages = {};
    const errors = utils_validation.validate(update_password, {
      new_password,
      password: this.state.currentPassword,
    });
    const passwordFormatErrors = this.validatePasswordFormat(new_password);
    if (!utils.is_obj_empty(errors) || passwordFormatErrors.length > 0) {
      const SPR = SET_PASSWORD_ERROR_RESPONSES;
      const SPD = SET_PASSWORD_ERROR_DISPLAY;
      const FORMAT = PASSWORD_FORMAT_ERRORS;

      if (errors.new_password === SPR.PASSWORD_EMPTY) {
        errorMessages.password = SPD.PASSWORD_EMPTY;
      } else if (errors.new_password === SPR.PASSWORD_INVALID) {
        errorMessages.password = SPD.PASSWORD_INVALID;
      }

      if (errors.password === SPR.PASSWORD_EMPTY) {
        errorMessages.currentPassword = SPD.PASSWORD_EMPTY;
      }

      if (
        passwordFormatErrors.includes(FORMAT.LENGTH) &&
        new_password.length >= 8
      ) {
        errorMessages.password = "Password too long.";
      }
      this.setState({ errors: errorMessages });
      this.passwordField.current.focus();
      return false;
    }
    return true;
  }

  validatePasswordFormat(new_password) {
    const FORMAT = PASSWORD_FORMAT_ERRORS;
    let passwordFormatErrors = utils.check_password_format(new_password);
    if (
      passwordFormatErrors.includes(FORMAT.LENGTH) &&
      new_password.length >= 8 &&
      new_password.length <= 200
    ) {
      // remove LENGTH error if password is between 8 and 200 characters
      passwordFormatErrors = passwordFormatErrors.filter(
        (item) => item !== FORMAT.LENGTH,
      );
    }
    this.setState({ passwordFormatErrors });
    return passwordFormatErrors;
  }

  validateNewPassword(new_password) {
    const errorMessages = {};
    const errors = this.state.user
      ? utils_validation.validate(appuser_validaterepeatedpassword, {
          user_id: this.state.user.user_id,
          password: new_password,
        })
      : {};
    const passwordFormatErrors = this.validatePasswordFormat(new_password);
    if (!utils.is_obj_empty(errors)) {
      const SPR = SET_PASSWORD_ERROR_RESPONSES;
      const SPD = SET_PASSWORD_ERROR_DISPLAY;
      const FORMAT = PASSWORD_FORMAT_ERRORS;
      if (errors.password === SPR.PASSWORD_EMPTY) {
        errorMessages.password = SPD.PASSWORD_EMPTY;
      } else if (errors.password === SPR.PASSWORD_INVALID) {
        errorMessages.password = SPD.PASSWORD_INVALID;
      }
      if (
        passwordFormatErrors.includes(FORMAT.LENGTH) &&
        new_password.length >= 8
      ) {
        errorMessages.password = "Password too long.";
      }
      this.setState({ errors: errorMessages });
      this.passwordField.current.focus();
      return false;
    }
    return true;
  }

  setPassword(password) {
    this.setState({ loader: true });
    if (
      this.state.user &&
      this.state.user.token_type === TOKEN_TYPE.RESET_PASSWORD
    ) {
      this.resetPassword(password);
    } else if (
      this.state.user &&
      this.state.user.token_type.includes(TOKEN_TYPE.REGISTRATION)
    ) {
      this.setPasswordFirstTime(password);
      if (this.props.utmParams) {
        this.props.update_user_utm_params(this.props.utmParams);
      }
    } else {
      this.updatePassword(this.state.currentPassword, password);
    }
  }

  setPasswordFirstTime(password) {
    // this on success is to login
    const success = () => this.props.onSuccess();
    const fail = (error) => {
      utilsGA.sendFormFieldsErrorsEventToGA(this.state.formName, {
        error: UNEXPECTED_ERROR,
      });
      this.setState({ errors: { password: UNEXPECTED_ERROR }, loader: false });
    };

    this.props
      .set_first_time_password_put(password)
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  resetPassword(password) {
    const success = () => {
      this.setState({ show2FA: false, loader: false });
      this.props.onSuccess();
    };
    const fail = (error) => {
      this.passwordField.current.focus();
      this.setState({ show2FA: false, loader: false });
      let errors = {};
      if (
        error.response &&
        error.response.status === RESPONSE_CODE["405_data_invalid"]
      ) {
        errors = { password: RESET_PWD_ERROR_DISPLAY.INVALID_PASSWORD };
      } else {
        errors = { password: UNEXPECTED_ERROR };
      }
      this.setState({ errors });
      utilsGA.sendFormFieldsErrorsEventToGA(this.state.formName, { ...errors });
    };

    this.props
      .reset_password_put(password)
      .then((response) => success())
      .catch((error) => fail(error));
  }

  updatePassword(currentPassword, password) {
    const success = () => {
      this.setState({ show2FA: false, loader: false });
      this.props.onSuccess();
    };
    const fail = (error) => {
      this.setState({ show2FA: false, loader: false });
      let errors = {};
      if (
        error.response &&
        error.response.status === RESPONSE_CODE["405_data_invalid"]
      ) {
        this.passwordField.current.focus();
        errors = { password: RESET_PWD_ERROR_DISPLAY.INVALID_PASSWORD };
      } else if (
        error.response &&
        error.response.status === RESPONSE_CODE["411_wrong_password"]
      ) {
        this.currentPasswordField.current.focus();
        errors = {
          currentPassword: RESET_PWD_ERROR_DISPLAY.CURRENT_PASSWORD_INCORRECT,
        };
      } else if (
        error.response &&
        error.response.status === RESPONSE_CODE["414_user_lockout"]
      ) {
        this.currentPasswordField.current.focus();
        errors = { currentPassword: RESET_PWD_ERROR_DISPLAY.USER_LOCKOUT };
        this.props.onLockedAccount();
      } else {
        this.passwordField.current.focus();
        errors = { password: UNEXPECTED_ERROR };
      }
      this.setState({ errors });
      utilsGA.sendFormFieldsErrorsEventToGA(this.state.formName, { ...errors });
    };
    this.props
      .update_password_put(currentPassword, password)
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  verifyPassword(currentPassword, password) {
    this.setState({ loader: true });
    const success = () => this.setState({ show2FA: true, loader: false });
    const fail = (error) => {
      this.setState({ loader: false });
      let errors = {};
      if (
        error.response &&
        error.response.status === RESPONSE_CODE["405_data_invalid"]
      ) {
        this.passwordField.current.focus();
        errors = { password: RESET_PWD_ERROR_DISPLAY.INVALID_PASSWORD };
      } else if (
        error.response &&
        error.response.status === RESPONSE_CODE["411_wrong_password"]
      ) {
        this.currentPasswordField.current.focus();
        errors = {
          currentPassword: RESET_PWD_ERROR_DISPLAY.CURRENT_PASSWORD_INCORRECT,
        };
      } else if (
        error.response &&
        error.response.status === RESPONSE_CODE["414_user_lockout"]
      ) {
        this.currentPasswordField.current.focus();
        errors = { currentPassword: RESET_PWD_ERROR_DISPLAY.USER_LOCKOUT };
        this.props.onLockedAccount();
      } else {
        this.passwordField.current.focus();
        errors = { password: UNEXPECTED_ERROR };
      }
      this.setState({ errors });
      utilsGA.sendFormFieldsErrorsEventToGA(this.state.formName, { ...errors });
    };
    this.props
      .verify_password_post(currentPassword, password)
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      this.validate(e.target.value);
    }
    // if (this.state.passwordFormatErrors) {
    //     this.setState({ passwordFormatErrors: null })
    // }
    if (this.state.errors) {
      this.setState({ errors: {} });
    }
  }

  handleFocus(event) {
    event.target.select();
    if (!this.state.start_form) {
      utilsGA.sendFormStartToGA(this.state.formName);
      this.setState({ start_form: true });
    }
    utilsGA.formFieldEvent(event, eventEnum.formFieldEnter);
  }

  handleBlur(event) {
    utilsGA.formFieldEvent(event, eventEnum.formFieldLeave);
  }

  renderCurrentPassword() {
    return (
      <Form.Group controlId="formBasicPassword">
        <Form.Label>{this.t("Current Password")}</Form.Label>
        <Form.Control
          onInput={(e) => this.handleChange(e)}
          name="currentPassword"
          type="password"
          aria-required="true"
          ref={this.currentPasswordField}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur}
        />
        <Form.Text className="text-danger form-error" aria-live="polite">
          {this.t(this.state.errors.currentPassword) || <br />}
        </Form.Text>
      </Form.Group>
    );
  }

  showPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onNewPasswordFocus(state) {
    // faking password focus
    this.setState({ passwordFocus: state });
  }

  on2faHandler() {
    if (this.state.user.token_type === "session") {
      this.setState({ show2FA: false });
      this.props.closeModal();
    } else {
      this.props.history.push(PAGES.LOGIN);
    }
  }

  on2FASuccess() {
    this.setPassword(this.state.password);
  }

  renderCheckmarks = () => {
    const { passwordFormatErrors } = this.state;
    const { t } = this.props;

    const renderCheckmark = (errorKey) => {
      if (!passwordFormatErrors) {
        return null;
      }
      return (
        !passwordFormatErrors.includes(errorKey) && (
          <span className="password-checkmark" />
        )
      );
    };

    const rules = [
      {
        title: t("Must be at least 8 characters long"),
        checkmark: renderCheckmark(PASSWORD_FORMAT_ERRORS.LENGTH),
      },
      {
        title: t("Must contain at least 1 capital and 1 lower case letter"),
        checkmark: renderCheckmark(PASSWORD_FORMAT_ERRORS.CASE),
      },
      {
        title: t("Must contain at least 1 number"),
        checkmark: renderCheckmark(PASSWORD_FORMAT_ERRORS.NUMBER),
      },
      {
        title: t("Must contain at least 1 special character"),
        checkmark: renderCheckmark(PASSWORD_FORMAT_ERRORS.SPECIAL_CHARACTER),
      },
    ];

    return (
      <div className="password-requirements fixed-padding">
        <strong className="label">{t("Password Requirements")}</strong>
        <ul style={{ fontSize: "12px" }}>
          {rules.map(({ title, checkmark }) => (
            <li key={title}>
              {title} {checkmark}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const {
      t,
      registrationType: propsRegType,
      disableButton,
      currentPassword,
    } = this.props;

    const {
      user,
      show2FA,
      password,
      passwordFormatErrors,
      loader,
      passwordFocus,
      showPassword,
      errors,
    } = this.state;

    const registrationType =
      propsRegType || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE);
    const tokenType = user && user.token_type ? user.token_type : null;
    // registrationType === TOKEN_TYPE.REGISTRATION_SELF || registrationType === TOKEN_TYPE.REGISTRATION_REFERRAL;
    const acceptNoUser = [
      TOKEN_TYPE.REGISTRATION_SELF,
      TOKEN_TYPE.REGISTRATION_REFERRAL,
    ].includes(registrationType);

    const isUserProfileAvailable = [
      TOKEN_TYPE.SESSION,
      TOKEN_TYPE.ENROLLMENT,
    ].includes(tokenType);

    if (!user && !acceptNoUser) {
      return <Spinner error="set password form" />;
    }
    if (show2FA) {
      return (
        <SetTwoFA
          type={EDIT_PERSONAL_MODAL.PASSWORD}
          onSuccess={() => this.on2FASuccess()}
          handler={() => this.on2faHandler()}
        />
      );
    }

    const submitBtnDisable =
      password === "" ||
      (passwordFormatErrors && passwordFormatErrors.length > 0) ||
      loader;

    if (this.props.isLockedAccount) {
      return (
        <>
          <p className="mb-4 warning-text font-weight-bold">
            {this.props.t(RESET_PWD_ERROR_DISPLAY.USER_LOCKOUT)}
          </p>
          <Button
            variant="primary"
            block
            onClick={() => this.props.onRequestLogout()}
          >
            {this.props.t("Close")}
          </Button>
        </>
      );
    }

    return (
      <Form
        noValidate
        onSubmit={(e) =>
          !submitBtnDisable ? this.handleSubmit(e) : e.preventDefault()
        }
        className="password-form ga4-track"
        data-testid="password-form"
        data-name={this.state.formName}
      >
        {isUserProfileAvailable ? this.renderCurrentPassword() : null}
        <Form.Group controlId="password">
          <Form.Label>
            {t(isUserProfileAvailable ? "New Password" : "Password")}
          </Form.Label>
          <div
            className={`input-new-password-wrapper${passwordFocus ? " focused" : ""}`}
          >
            <Form.Control
              className="input-new-password"
              onInput={(e) => this.handleChange(e)}
              onBlur={(e) => {
                this.onNewPasswordFocus(false);
                this.handleBlur(e);
              }}
              onFocus={(e) => {
                this.onNewPasswordFocus(true);
                this.handleFocus(e);
              }}
              name="password"
              type={showPassword ? "text" : "password"}
              aria-required="true"
              ref={this.passwordField}
              autoFocus
              data-testid="input-new-password"
              maxLength={200}
            />
            <Button
              variant="link"
              className="btn-show-password font-weight-bold font-callout"
              onClick={() => this.showPassword()}
            >
              {this.t(showPassword ? "HIDE" : "SHOW")}
            </Button>
          </div>

          <Form.Text className="text-danger form-error" aria-live="polite">
            {this.t(errors.password) || ""}
          </Form.Text>
        </Form.Group>

        {this.renderCheckmarks()}

        <div className="password-submit-wrapper">
          {(tokenType && tokenType.includes(TOKEN_TYPE.REGISTRATION)) ||
          !user ? (
            <Button
              variant={
                submitBtnDisable || disableButton ? "disable" : "primary"
              }
              type="submit"
              block
            >
              {t("Set Password")}
            </Button>
          ) : null}

          {tokenType === TOKEN_TYPE.RESET_PASSWORD ? (
            <Button
              variant={submitBtnDisable ? "disable" : "primary"}
              type="submit"
              block
            >
              {t("Reset Password")}
            </Button>
          ) : null}
          {isUserProfileAvailable ? (
            <Button
              variant={
                submitBtnDisable || currentPassword === ""
                  ? "disable"
                  : "primary"
              }
              type="submit"
              block
            >
              {t("Update Password")}
            </Button>
          ) : null}

          <Form.Text className="text-center text-danger form-error">
            {errors.system || <br />}
          </Form.Text>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, {
    reset_password_put,
    update_password_put,
    set_first_time_password_put,
    verify_password_post,
    get_utm_params,
    update_user_utm_params,
  })(withTranslation()(SetPasswordForm)),
);
